<template>
	<div class="page-show-item change_responsive show-user" v-if="loaded">
		<div class="main-content">
			<div class="header-with-tabs row-space-tbf">
				<div class="space-left"></div>
				<div class="content">
					<div class="tabs">
						<div class="title-tab active">{{ $t("show_user.tab_responsibilities") }}</div>
					</div>
					<div class="actions"></div>
				</div>
				<div class="space-right"></div>
			</div>

			<div class="tab-content">
				<tab-list-responsibilities :data="{ slug: vacantJob.slug, id: vacantJob.id, from: 'vacant_job' }" />
			</div>
		</div>
		<div class="sidebar-user">
			<div class="space-left-custom"></div>
			<div class="content">
				<div class="actions-header" v-if="$auth.user().rights.admins_crud">
					<button @click="$router.push({ name: 'vacant-jobs-edit', params: { slug: vacantJob.slug } })">
						<div class="text ">{{ $t("general.edit") }}</div>
					</button>

					<button
						class="delete-btn"
						@click="showModal('delete', { type: 'vacantJob', from: 'show_vacant_job', model: vacantJob })"
					>
						<div class="text ">{{ $t("general.delete") }}</div>
					</button>
				</div>

				<div class="header-user">
					<div class="data">
						<div class="name">{{ vacantJob.name }}</div>
					</div>
				</div>

				<div class="box-label-text" v-if="vacantJob.roles.length">
					<div class="label">{{ $t("general.roles") }}</div>
					<div class="text">{{ vacantJob.roles.map((a) => a.name).join(", ") }}</div>
				</div>

				<div class="box-label-text" v-if="vacantJob.departments.length">
					<div class="label">{{ $t("profile.departments") }}</div>
					<div class="text">{{ vacantJob.departments.map((a) => a.name).join(", ") }}</div>
				</div>

				<div class="box-label-text" v-if="vacantJob.workstation">
					<div class="label">{{ $t("create-user.workstation") }}</div>
					<div class="text">{{ vacantJob.workstation.name }}</div>
				</div>

				<div class="box-label-text">
					<div class="label">{{ $t("job_announcement.title_list") }}</div>
					<div class="text-with-action" v-if="vacantJob.vacant_job_announce">
						<router-link
							class="link"
							:to="{ name: 'vacant-job-announcement', params: { id: vacantJob.vacant_job_announce.id } }"
							>{{
								$t("job_announcement.updated_at", {
									date: $options.filters.moment(vacantJob.vacant_job_announce.updated_at, "DD MMM YYYY hh:mm"),
								})
							}}</router-link
						>
						<div class="dropdown edit-inline-dropdown">
							<button class="btn-edit-square" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<icon-edit class="icon" />
							</button>
							<div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownFilter">
								<div
									class="dropdown-item"
									@click="
										$router.push({ name: 'vacant-job-announcement', params: { id: vacantJob.vacant_job_announce.id } })
									"
								>
									<span class="text-dropdown">{{ $t("general.edit") }}</span>
								</div>
								<div
									class="dropdown-item"
									@click="
										showModal('delete', {
											type: 'vacantJobAnnouncement',
											from: 'show_vacant_job_announcement',
											model: vacantJob.vacant_job_announce,
										})
									"
								>
									<span class="text-dropdown">{{ $t("general.delete") }}</span>
								</div>
							</div>
						</div>
					</div>
					<button id="buttonGenerate" class="btn-tbf blue center btn-generate" @click="generateJobAnnouncement" v-else>
						<div class="loader-spin icon" v-if="loadingGenerateAnnouncement"></div>
						<div class="text-btn" v-else><icon-gears />{{ $t("job_announcement.generate") }}</div>
					</button>
				</div>

				<div class="accordion-sidebar" v-if="vacantJob.work_schedule">
					<div class="header-accordion collapsed" data-toggle="collapse" data-target="#collapseWorkSchedules">
						<div class="icon"><icon-work-schedule /></div>

						<div class="title">{{ $t("show_user.work-schedule") }}</div>
						<div class="actions">
							<button
								class="arrow-collapse show collapsed"
								type="button"
								data-toggle="collapse"
								data-target="#collapseWorkSchedules"
								aria-expanded="true"
							>
								<icon-arrow />
							</button>
						</div>
					</div>

					<div class="collapse content-accordion" id="collapseWorkSchedules">
						<div class="box-radius">
							<div class="inline-data" v-for="schedule in vacantJob.work_schedule.schedules">
								<div class="label">{{ $t(`general.${schedule.day}`) }}</div>
								<div class="value">{{ `${schedule.start_time} - ${schedule.end_time}` }}</div>
							</div>
						</div>
					</div>
				</div>

				<div class="accordion-sidebar" v-if="vacantJob.skills.length">
					<div class="header-accordion collapsed" data-toggle="collapse" data-target="#collapseSkills">
						<div class="icon"><icon-skill /></div>

						<div class="title">{{ $t("show_user.skills") }}</div>
						<div class="actions">
							<button
								class="arrow-collapse show collapsed"
								type="button"
								data-toggle="collapse"
								data-target="#collapseSkills"
								aria-expanded="true"
							>
								<icon-arrow />
							</button>
						</div>
					</div>

					<div class="collapse content-accordion" id="collapseSkills">
						<div class="box-radius">
							<div class="inline-data" v-for="skill in vacantJob.skills">
								<v-popover
									offset="5"
									trigger="hover"
									placement="bottom"
									popoverBaseClass="popover-tbf"
									class="label"
									:delay="{ show: 100, hide: 0 }"
								>
									<div class="label">{{ skill.name }}</div>
									<template slot="popover">
										<div class="simple-text">{{ skill.description }}</div>
									</template>
								</v-popover>

								<div class="value">{{ $t(`skill.type.${skill.level}`) }}</div>
							</div>
						</div>
					</div>
				</div>

				<div class="accordion-sidebar" v-if="vacantJob.foreign_languages.length">
					<div class="header-accordion collapsed" data-toggle="collapse" data-target="#collapseForeignLanguages">
						<div class="icon"><icon-globe /></div>

						<div class="title">{{ $t("show_user.foreign_languages") }}</div>
						<div class="actions">
							<button
								class="arrow-collapse show collapsed"
								type="button"
								data-toggle="collapse"
								data-target="#collapseForeignLanguages"
								aria-expanded="true"
							>
								<icon-arrow />
							</button>
						</div>
					</div>

					<div class="collapse content-accordion" id="collapseForeignLanguages">
						<div class="box-radius">
							<div class="data" v-for="foreign_language in vacantJob.foreign_languages">
								<div class="label">{{ foreign_language.name }}</div>
								<div class="value">
									{{
										foreign_language.levels
											.map((el) => {
												return `${$t("foreign_languages." + el["type"])}: ${el["level"]}`;
											})
											.join(", ")
									}}
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="accordion-sidebar">
					<div class="header-accordion collapsed" data-toggle="collapse" data-target="#collapseExport">
						<div class="icon"><icon-download /></div>
						<div class="title">{{ $t("general.export") }}</div>
						<div class="actions">
							<button
								class="arrow-collapse show collapsed"
								type="button"
								data-toggle="collapse"
								data-target="#collapseExport"
								aria-expanded="true"
							>
								<icon-arrow />
							</button>
						</div>
					</div>

					<div class="collapse content-accordion" id="collapseExport">
						<div class="box-radius border-0">
							<div class="inline-data">
								<div class="label">{{ $t("vacant_jobs.download_single") }}</div>
								<div class="value link" @click="downloadPdf">
									<div class="loader-spin" v-if="loadingExportPdf"></div>
									{{ $t("general.download") }}
								</div>
							</div>
							<div class="inline-data" v-if="vacantJob.vacant_job_announce">
								<div class="label">{{ $t("job_announcement.download") }}</div>
								<div class="value link" @click="downloadAnnouncement">
									<div class="loader-spin" v-if="loadingExportAnnouncement"></div>
									{{ $t("general.download") }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="space-right-custom"></div>
		</div>
	</div>
</template>

<script>
import IconSkill from "../../Icons/Skill";
import IconWorkSchedule from "../../Icons/WorkSchedule";
import IconGlobe from "../../Icons/Globe";
import IconGears from "../../Icons/Gears";
import IconFile from "../../Icons/FilePdf";
import IconSearch from "../../Icons/Search";
import IconArrow from "../../Icons/Arrow";
import IconUser from "../../Icons/User";
import IconTrash from "../../Icons/Trash";
import IconClose from "../../Icons/Close";
import IconEdit from "../../Icons/EditDots";
import IconSettings from "../../Icons/Settings";
import IconDate from "../../Icons/Date";
import IconEditMobile from "../../Icons/Edit";
import IconPlus from "../../Icons/Plus";
import IconRole from "../../Icons/Role";
import IconDownload from "../../Icons/Download";
import TabListResponsibilities from "../Roles/ResponsibilitiesList";

export default {
	components: {
		IconSkill,
		IconWorkSchedule,
		IconGlobe,
		IconFile,
		IconSearch,
		IconArrow,
		IconUser,
		IconTrash,
		IconClose,
		IconEdit,
		IconSettings,
		IconEditMobile,
		IconDate,
		IconPlus,
		IconRole,
		TabListResponsibilities,
		IconDownload,
		IconGears,
	},
	data() {
		return {
			loaded: false,
			loadedList: false,
			vacantJob: {},
			loadingExportPdf: false,
			loadingExportAnnouncement: false,
			loadingGenerateAnnouncement: false,
		};
	},
	async mounted() {
		await this.getVacantJobData();

		setTimeout(() => {
			var title = this.vacantJob.name;
			this.$root.$emit("navbar_title", title);
		}, 0);

		this.$root.$on("refreshVacantJobPage", () => {
			this.getVacantJobData();
		});
	},
	computed: {
		sortedProcedures: function() {
			return this.procedures.sort((p1, p2) => {
				let modifier = 1;
				if (this.sortDirection === "desc") modifier = -1;
				if (p1[this.sortBy] < p2[this.sortBy]) return -1 * modifier;
				if (p1[this.sortBy] > p2[this.sortBy]) return 1 * modifier;
				return 0;
			});
		},
		filteredProcedures() {
			return getByKeyword(this.sortedProcedures, this.search_filter);
		},
		filteredRoles() {
			return getByKeywordFilter(this.filterRoles, this.searchRoles);
		},
	},
	methods: {
		async getVacantJobData() {
			await axios
				.get(`/vacant-jobs/${this.$route.params.slug}/show`)
				.then(({ data }) => {
					this.vacantJob = data.data;
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 403) {
							this.$router.push({ name: "forbbiden" });
						} else if (error.response.status == 404) {
							this.$router.push({ name: "not-found" });
						} else if (error.response.status == 500) {
							alert(this.$t("error.500"));
						}
					}
				})
				.finally(() => {
					this.loaded = true;
				});
		},
		showModal(type, data = false, itemId = false) {
			this.$root.$emit("open_modal", type, data, itemId);
		},
		downloadPdf() {
			this.loadingExportPdf = true;

			axios.get(`reports/${this.vacantJob.slug}/vacant-job`, { responseType: "blob" }).then((response) => {
				let blob = new Blob([response.data], { type: "application/octet-stream" });
				let fileURL = window.URL.createObjectURL(blob);

				let fileName = `${this.vacantJob.name}.pdf`;

				let fileLink = document.createElement("a");
				fileLink.href = fileURL;
				fileLink.setAttribute("download", fileName);
				document.body.appendChild(fileLink);

				fileLink.click();

				setTimeout(() => {
					this.loadingExportPdf = false;
				}, 100);
			});
		},
		generateJobAnnouncement() {
			var btnSubmit = document.getElementById("buttonGenerate");
			btnSubmit.disabled = true;
			this.loadingGenerateAnnouncement = true;

			axios.post(`/vacant-job-announce/${this.vacantJob.slug}/store`).then(({ data }) => {
				this.$router.push({ name: "vacant-job-announcement", params: { id: data.data } });
			});
		},
		downloadAnnouncement() {
			this.loadingExportAnnouncement = true;

			axios
				.get(`/vacant-job-announce/${this.vacantJob.vacant_job_announce.id}/announce-pdf`, { responseType: "blob" })
				.then((response) => {
					let blob = new Blob([response.data], { type: "application/octet-stream" });
					let fileURL = window.URL.createObjectURL(blob);

					let fileName = `${this.$t("announcements.pdf_title")} ${this.vacantJob.name}.pdf`;

					let fileLink = document.createElement("a");
					fileLink.href = fileURL;
					fileLink.setAttribute("download", fileName);
					document.body.appendChild(fileLink);

					fileLink.click();

					setTimeout(() => {
						this.loadingExportAnnouncement = false;
					}, 100);
				});
		},
	},
};
function getByKeyword(list, keyword) {
	const search = keyword.trim();

	if (!search.length) return list;
	return list.filter((item) => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1);
}
function getByKeywordFilter(list, keyword) {
	const search = keyword.trim();

	if (!search.length) return list;
	return list.filter((item) => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1);
}
</script>

<style lang="scss">
@import "../../../styles/pages/user_show.scss";
.filter-btns {
	display: flex;
	align-items: center;
	margin-right: 20px;
	&.mr-0 {
		margin-right: 0;
	}
	.btn-default-tbf {
		height: 36px;
		font-size: 12px;
		font-weight: 500;
		color: $primary;
		padding: 0 15px;
		min-width: 110px;
		text-align: left;
		display: flex;
		align-items: center;
		.icon-arrow {
			margin-left: auto;
		}
		.clearFilter {
			margin-left: auto;
			height: 12px;
			margin-right: -2px;
		}
	}
	.calendar-filter {
		margin-left: 20px;
	}
	.filter-status {
		margin-left: 20px;
	}
}
.input-picker {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	.icon {
		margin-right: 10px;
		justify-content: center;
		display: flex;
		svg {
			height: 16px;
			width: auto;
			.fill-color {
				fill: $placeholderColor;
			}
		}
	}
	.text {
		color: $placeholderColor;
	}
	.date {
		flex: 1;
		font-size: 12px;
		color: $primary;
		text-transform: capitalize;
		line-height: normal;
	}
}
.responsibility {
	margin: 0 0 10px 0;
	padding-left: 15px;
	line-height: 1.3;
	margin-top: 10px;
}
.filter-dropdown {
	&.second,
	&.third {
		margin-left: 20px;
		button {
			height: 36px !important;
		}
		@media (max-width: 1065px) {
			max-width: 100px !important;
		}
	}
}
.vue-daterange-picker {
	&.mobile {
		.form-control {
			height: 36px !important;
		}
	}
}
.btn-tbf {
	&.show {
		height: 36px !important;
	}
	&.drop {
		min-width: 100px !important;
	}
}
</style>
